/* Write your custom CSS here */
.nav-section__title {
    font-size: .95em;
    color: var(--color-primary-bg-solid);
}
.nav-list__link {
    font-size: 0.875em;
}
.note-item {
    margin-bottom: 1rem;
}

.note-link {
    display: block;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.note-excerpt {
    font-size: 0.9rem;
    color: #666;
    margin-left: 0.5rem;
    line-height: 1.4;
}
